<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <div class="textCard">
          <!-- <h1>Cam kết bảo mật</h1> -->
          <!-- <div class="title-line"></div> -->
          <div class="textBox" style="white-space: pre-wrap">{{ text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import importedImg1 from "@/page/nhdinvestcompany/components/img/4_1.png";

export default {
  data() {
    return {
      altText: "protocolTitle",
      importedImg1: importedImg1,
      text: `chúng ta sẽ tuân thủ nghiêm ngặt chính sách bảo mật người dùng và chính sách bảo mật dữ liệu.
Vui lòng đọc kỹ Chính sách Bảo mật này trước khi bạn truy cập và sử dụng Dịch vụ. Chính sách Bảo mật này bao gồm các điều khoản về quyền lợi hợp pháp của bạn. Bằng cách gửi đơn đăng ký, bạn đồng ý bị ràng buộc bởi các điều khoản và điều kiện sau và tự động đồng ý với việc thu thập, sử dụng, lưu trữ, phân tích thông tin dữ liệu của bạn. Nếu bạn đồng ý với Chính sách quyền riêng tư này, sẽ cung cấp cho bạn trải nghiệm thú vị. Nếu bạn không muốn bị ràng buộc bởi các điều khoản và điều kiện này, bạn sẽ không thể truy cập và sử dụng Dịch vụ chúng ta.
Xin lưu ý rằng Chính sách quyền riêng tư này có thể được sửa đổi theo thời gian để đảm bảo tuân thủ các yêu cầu về quyền riêng tư dữ liệu, phạm vi và sự tối ưu hóa về bảo mật công nghệ. Và các bản sửa đổi đó sẽ có hiệu lực ngay lập tức và sau đây là danh sách các thỏa thuận được sửa đổi.
1. Thu thập dữ liệu
1.1 Dữ liệu cá nhân
"Thông tin chứng minh cá nhân" là thông tin có thể được sử dụng để nhận dạng duy nhất một người dùng, chẳng hạn như tên, mật khẩu, ngày sinh, quốc tịch, giới tính, địa chỉ email, số điện thoại và một số chi tiết cá nhân khác. Thông tin của bạn có thể được thu thập bao gồm (nhưng không giới hạn):
• Họ và tên;
• Số CMND / CCCD;
• Thông tin về việc làm và thu nhập;
• Tuổi tác;
• Nơi ở;
• Bất kỳ thông tin cá nhân nào bạn cung cấp cho chúng tôi liên quan đến thông tin liên lạc dịch vụ chăm sóc khách hàng (email, điện thoại hoặc các hình thức khác), tranh chấp hoặc khiếu nại.
Bạn có thể được yêu cầu cung cấp thông tin chứng minh cá nhân để tuân thủ các nghĩa vụ pháp lý của chúng tôi, xác minh danh tính của bạn, xác định tính đủ điều kiện tín dụng, ngăn chặn gian lận và hoàn thành các giao dịch của bạn. Chúng tôi cũng có thể sử dụng thông tin cá nhân được cung cấp để thông báo cho bạn về các đề nghị cho vay cụ thể, xử lý khoản vay của bạn, cung cấp cho bạn các dịch vụ của chúng tôi hoặc thực hiện các cuộc khảo sát. Trong trường hợp chúng ta tự nguyện thu thập thông tin chứng minh cá nhân cho các sản phẩm và dịch vụ nhất định, việc thu thập thông tin chứng minh cá nhân là một yêu cầu thiết yếu để truy cập dịch vụ của chúng tôi hoặc xử lý các khoản vay của người dùng.
1.2 Thu thập thông tin
Thông tin được thu thập khi sử dụng ứng dụng
a) Tin nhắn SMS
Nội dung được thu thập và giám sát: Với sự đồng ý của bạn, chúng tôi sẽ truy cập danh sách SMS của bạn.
Mục đích: Để xác định và phân tích hành vi và rủi ro của bạn khi bạn có nhiều khoản vay, để đánh giá liệu khoản vay có thể được xử lý thông qua ngăn chặn gian lận bằng cách thu thập và theo dõi các giao dịch chi tiết và số lượng tin nhắn văn bản tài chính và phi cá nhân hay không. Nó sẽ được sử dụng để tính toán khả năng hội đủ điều kiện và đánh giá rủi ro của khách hàng. Các tin nhắn văn bản khác sẽ không được đánh giá. Chúng tôi phân tích tin nhắn văn bản của người dùng, xác minh và theo dõi các giao dịch tài chính của người dùng trong nền tảng và đưa ra quyết định chính xác về hạn mức tín dụng dựa trên ngân sách chi tiêu và khả năng thanh toán của người dùng. Điều này giúp chúng tôi xác định các tài khoản ngân hàng khác nhau mà bạn có thể có, các mẫu dòng tiền và giúp bạn xử lý số tiền giao dịch với tư cách là khách hàng.
Lưu trữ dữ liệu: Chúng tôi sẽ tuân thủ nghiêm ngặt chính sách dữ liệu người dùng, sẽ không sử dụng dữ liệu cho các mục đích khác và sẽ không chia sẻ dữ liệu với bất kỳ bên thứ ba nào. Dữ liệu sẽ được mã hóa và tải lên máy chủ chuyên dụng của chúng tôi    thông qua giao thức HTTPS.
b) Lịch sử cuộc gọi
Theo dõi và thu thập: Khi bạn đồng ý, chúng tôi sẽ nhận thông tin lịch sử cuộc gọi của bạn.
Mục đích: Trong quá trình đăng ký khoản vay, giúp bạn xây dựng hồ sơ tín dụng đồng thời từ nhiều góc độ đánh giá điểm tín dụng của bạn và cung cấp thông tin chính xác đến bạn 
Lưu trữ lịch sử cuộc gọi: Lưu trữ lịch sử cuộc gọi: Khi bạn đồng ý, thông tin lịch sử cuộc gọi sẽ được mã hóa và tải lên máy chủ chuyên dụng của chúng tôi   thông qua giao thức HTTPS. Chúng tôi không chia sẻ dữ liệu này với bên thứ ba.
c) Vị trí
Giám sát và thu thập: Với sự đồng ý của bạn, để thu thập và giám sát thông tin về vị trí của thiết bị của bạn
Mục đích: Dựa trên thông tin vị trí của thiết bị, tính toán và thiết lập mô hình điểm tín dụng của người dùng để tránh việc mất hoặc đánh cắp thiết bị của người dùng, gây ra những rắc rối không đáng có và ảnh hưởng đến dịch vụ khách hàng.
Lưu trữ dữ liệu: Với sự đồng ý của bạn, dữ liệu sẽ được mã hóa và tải lên máy chủ chuyên dụng  của chúng tôi thông qua giao thức HTTPS. Chúng tôi không chia sẻ dữ liệu này với các bên thứ ba.
d)Ứng dụng đã cài đặt
Những gì chúng tôi theo dõi và thu thập: Chúng tôi sẽ thu thập danh sách các ứng dụng trong điện thoại của bạn
Mục đích: Chúng tôi sẽ sử dụng dữ liệu này để đảm bảo rằng không có ứng dụng đáng tin cậy nào không phải của Google hoặc ứng dụng vi-rút được cài đặt trên điện thoại của bạn vì những ứng dụng này có thể ảnh hưởng đến tính bảo mật của tiền của bạn.
Lưu trữ dữ liệu ứng dụng đã cài đặt: Dữ liệu điện thoại và thiết bị sẽ được mã hóa và tải lên máy chủ chuyên dụng của chúng tôi  thông qua giao thức HTTPS.
e) Điện thoại và thiết bị
Giám sát và Thu thập: Với sự đồng ý của bạn, chúng tôi thu thập và giám sát một số thông tin nhất định về thiết bị của bạn, bao gồm nhưng không giới hạn ở tên thiết bị, kiểu máy, số điện thoại, khu vực và cài đặt ngôn ngữ, số nhận dạng thiết bị, thông tin phần cứng và phần mềm của thiết bị, địa chỉ IP, Trạng thái, thói quen sử dụng, số nhận dạng thiết bị duy nhất, v.v. dưới dạng IMEI và số sê-ri.
Mục đích: Để xác định các thiết bị duy nhất và đảm bảo các thiết bị trái phép không phải bạn thao tác để ngăn chặn gian lận.
Lưu trữ dữ liệu điện thoại và thiết bị: Với sự đồng ý của bạn, dữ liệu điện thoại và thiết bị sẽ được mã hóa và tải lên máy chủ chuyên dụng  của chúng tôi thông qua giao thức HTTPS. Chúng tôi không chia sẻ dữ liệu này với các bên thứ ba.
f) Máy ảnh
Với sự đồng ý của bạn, quyền này sẽ cho phép bạn tải lên ảnh hoặc tệp để xác minh rằng hành động được thực hiện bởi bạn chứ không phải ai khác.
g) Lưu trữ dữ liệu
Theo dõi và thu thập: Khi bạn chủ động tải ảnh lên bằng chức năng chụp ảnh và các chức năng khác, chúng ta sẽ thu thập ảnh của bạn như một phần thông tin với sự cho phép của bạn. Đồng thời thu thập thông tin tài liệu của bạn để đánh giá hồ sơ rủi ro và xác định tính đủ điều kiện cho khoản vay của bạn.
Mục đích: Giúp bạn điền các hình ảnh liên quan và các thông tin khác trong quá trình làm hồ sơ vay. Ngoài ra, thông tin này là cần thiết để phân tích rủi ro để chúng tôi có thể kiểm tra và tham chiếu để đánh giá hồ sơ rủi ro và xác định tính đủ điều kiện cho khoản vay của bạn.
Lưu trữ dữ liệu: Với sự đồng ý của bạn, dữ liệu sẽ được mã hóa và tải lên máy chủ chuyên dụng  của chúng tôi thông qua giao thức HTTPS. Chúng tôi không chia sẻ dữ liệu này với các bên thứ ba.
h) Lịch
Thu thập và giám sát dữ liệu: Với sự cho phép của bạn, chúng tôi truy cập vào lịch của bạn.
Mục đích: Chúng tôi thu thập và sử dụng thông tin lịch của bạn để giúp bạn viết các cuộc hẹn để thông báo cho bạn khi bạn đã lên lịch.
Lưu trữ dữ liệu: Với sự đồng ý của bạn, dữ liệu sẽ được mã hóa và tải lên máy chủ chuyên dụng  của chúng tôi thông qua giao thức HTTPS. Chúng tôi không chia sẻ dữ liệu này với các bên thứ ba.
i) Dung lượng pin
Giám sát và Thu thập: Thu thập thông tin mức pin của người dùng với sự đồng ý của bạn.
Mục đích: Để tối ưu hóa hiệu suất ứng dụng, giảm mức tiêu thụ pin của người dùng và kéo dài tuổi thọ thiết bị của người dùng.
Lưu trữ dữ liệu: Với sự đồng ý của bạn, dữ liệu sẽ được mã hóa và tải lên máy chủ chuyên dụng  của chúng tôi thông qua giao thức HTTPS. Chúng tôi không chia sẻ dữ liệu này với các bên thứ ba.
j) Dữ liệu mạng Wi-Fi
Giám sát và Thu thập: Với sự đồng ý của bạn, chúng tôi sẽ giám sát trạng thái mạng của người dùng.
Mục đích: Để tránh rủi ro dữ liệu trong quá trình sử dụng; những dữ liệu này được sử dụng để cải thiện độ chậm của các yêu cầu mạng và cung cấp cho bạn trải nghiệm tốt hơn.
Lưu trữ dữ liệu WiFi: Với sự đồng ý của bạn, dữ liệu WiFi sẽ được mã hóa và tải lên máy chủ chuyên dụng  của chúng tôi thông qua giao thức HTTPS. Chúng tôi không chia sẻ dữ liệu này với các bên thứ ba.
k) Thông tin khác
Giám sát và thu thập: với sự đồng ý của bạn, chúng tôi thu thập dữ liệu như bộ nhớ, tương tác với dịch vụ ứng dụng, thông tin sự cố và thông tin anr.
Mục đích: Thông tin trên sẽ được sử dụng để phân tích hiệu suất ứng dụng và cung cấp cho bạn trải nghiệm dịch vụ tốt hơn.
Lưu trữ dữ liệu: Với sự đồng ý của bạn, dữ liệu sẽ được mã hóa và tải lên máy chủ chuyên dụng  của chúng tôi thông qua giao thức HTTPS. Chúng tôi không chia sẻ dữ liệu này với các bên thứ ba.
2. Cách sử dụng
Thông tin nhận dạng cá nhân sẽ được chúng ta sử dụng trong nội bộ để cung cấp, phát triển và cải thiện các dịch vụ do người dùng đăng ký và đáp ứng các yêu cầu của người dùng. Điều này có thể bao gồm:
• Tạo hồ sơ vay và xử lý hồ sơ vay của bạn;
• Đặt hạn mức vay của Vdong cho bạn;
• Thực hiện các hoạt động thu thập khi cần thiết;
• Liên hệ với bạn về tài khoản và giao dịch của bạn;
• Giải quyết bất kỳ tranh chấp nào bạn có thể có với tài khoản của mình;
• Thông tin người vay: Khách hàng sẽ điền một số thông tin cần thiết, thông tin này là bắt buộc.
• Thông tin liên lạc: với sự đồng ý của khách hàng, dữ liệu được báo cáo về máy chủ, và thông tin sẽ được sử dụng cho các dịch vụ chống gian lận.
• Thông tin khác: Khi được sự đồng ý của khách hàng, dữ liệu được báo cáo về máy chủ, và thông tin này sẽ được sử dụng cho các dịch vụ chống gian lận và phát hiện chương trình hoặc các vấn đề khác.
Thông tin chúng tôi nắm giữ:
• Tên, địa chỉ, số điện thoại, địa chỉ email, ngày sinh, việc làm và chi tiết tài chính, tài khoản ngân hàng của bạn;
• Thông tin nhân khẩu học và lối sống;
• Thông tin chúng tôi nhận được khi đưa ra quyết định về bạn, khoản vay của bạn, hoặc đơn đăng ký của bạn (bao gồm thông tin thu thập từ các hiệp hội tín dụng);
• Chi tiết về các khoản nợ của bạn đối với chúng tôi và tất cả các giao dịch;
• Chi tiết về thời điểm bạn liên hệ với chúng tôi và khi nào chúng tôi liên hệ với bạn;
• Và bất kỳ thông tin nào khác mà chúng tôi cần một cách hợp lý để vận hành tài khoản của bạn, đưa ra quyết định về bạn hoặc tuân thủ các nghĩa vụ quy định của chúng tôi.
Chúng tôi có thể giữ thông tin chi tiết về bất kỳ số điện thoại nào bạn gọi cho chúng tôi và sử dụng để liên hệ với bạn.
• Tham chiếu đến thông tin cá nhân bao gồm tất cả những điều trên, cho dù được lấy từ bạn, hiệp hội tín dụng, Internet hay bất kỳ nguồn nào khác.
Dữ liệu này cũng sẽ được sử dụng để cung cấp và duy trì các dịch vụ của chúng tôi, bao gồm cả việc giám sát việc sử dụng các dịch vụ của chúng tôi.
Quản lý tài khoản của bạn: Quản lý đăng ký của bạn với tư cách là người dùng dịch vụ. Dữ liệu cá nhân bạn cung cấp cho phép bạn truy cập các tính năng khác nhau của dịch vụ có sẵn với tư cách là người dùng đã đăng ký.
Liên hệ với bạn: Để liên hệ với bạn qua email, SMS hoặc thông tin liên lạc điện tử tương đương khi cần thiết hoặc hợp lý, chẳng hạn như để cập nhật chức năng của các ứng dụng di động, sản phẩm và dịch vụ theo hợp đồng (bao gồm các bản cập nhật bảo mật) hoặc để quảng bá các thông báo liên lạc.
Để cung cấp cho bạn tin tức, ưu đãi đặc biệt và thông tin chung về hàng hóa, dịch vụ tương tự và các sự kiện khác mà bạn đã mua hoặc yêu cầu, trừ khi bạn đã chọn không nhận thông tin đó.
Quản lý các xác nhận quyền sở hữu của bạn: Tham gia với chúng tôi và quản lý các xác nhận quyền sở hữu của bạn.
Dùng cho các Mục đích Khác: Chúng tôi có thể sử dụng thông tin của bạn cho các mục đích khác, chẳng hạn như phân tích dữ liệu, xác định xu hướng sử dụng, xác định hiệu quả của các chương trình khuyến mãi khác, đánh giá và cải thiện dịch vụ, sản phẩm, tiếp thị và trải nghiệm của bạn.
3. Chia sẻ dữ liệu
chúng ta sẽ không chia sẻ bất kỳ thông tin chứng minh cá nhân nào với bên thứ ba, ngoại trừ được mô tả trong chính sách này và sự đồng ý đã ký của khách hàng. chúng ta cũng xử lý dữ liệu ẩn danh của chúng ta (cho dù tổng hợp hay không) để phân tích và tạo ra các chỉ số liên quan đến việc sử dụng sản phẩm và nhân khẩu học của người dùng. Dữ liệu ẩn danh như vậy không cho phép xác định người dùng được liên kết với nó. chúng ta có thể chia sẻ dữ liệu ẩn danh với các bên thứ ba, cho dù tổng hợp hay không.
Thông tin chúng tôi chia sẻ:  
• Làm việc với bất kỳ công ty, tổ chức hoặc cá nhân nào mà chúng tôi sử dụng để giúp chúng tôi tiến hành hoạt động kinh doanh cho vay, thu tiền và nợ, hoặc cung cấp dịch vụ thay mặt chúng tôi;
• Bất kỳ tổ chức hoặc cá nhân nào mà chúng tôi cung cấp sản phẩm hoặc dịch vụ hoặc chúng tôi cung cấp sản phẩm và dịch vụ cho ai;
• Bất kỳ cá nhân nào nói chuyện với chúng tôi và chúng tôi có lý do để tin rằng đó là cha mẹ, người chăm sóc hoặc người hỗ trợ của bạn khi có bằng chứng cho thấy bạn không thể tự giải quyết vấn đề do suy giảm nhận thức hoặc các vấn đề tương tự khác; công việc của bạn;
• Đối với bất kỳ hệ thống thanh toán nào chúng tôi có thể sử dụng;
• Cơ quan có thẩm quyền về phát hiện và ngăn chặn khủng bố (bao gồm cả những tổ chức bên ngoài Việt Nam);
• Đối với bất kỳ ai mà chúng tôi bán hoặc chuyển nhượng (hoặc thương lượng bán hoặc chuyển nhượng) doanh nghiệp của chúng tôi hoặc bất kỳ quyền và nghĩa vụ nào của chúng tôi theo bất kỳ thỏa thuận nào mà chúng tôi có với bạn. Trong trường hợp chuyển nhượng hoặc bán, người được chuyển nhượng hoặc người mua có thể sử dụng thông tin cá nhân của bạn theo cách giống như chúng tôi;
• Hợp tác với các cơ quan quản lý và các cơ quan chính phủ (bao gồm, nhưng không giới hạn ở, Văn phòng Thuế, Cục Điều tra Quốc gia, Thanh tra viên), bao gồm cả những cơ quan ở nước ngoài mà chúng tôi bắt buộc phải làm như vậy;
• Hợp tác với hiệp hội tín dụng hoặc bất kỳ tổ chức tương tự nào cung cấp dịch vụ loại ứng dụng tập trung để thu thập thông tin và đăng ký thế chấp và / hoặc cấp tín dụng nhằm mục đích ngăn ngừa và phát hiện gian lận. Chúng tôi cũng có thể cung cấp thông tin cho các bên thứ ba để giúp họ quyết định có cung cấp cho bạn một khoản vay, cho phép bạn gia hạn thời hạn thanh toán hoặc hợp tác kinh doanh với bạn hay không.
4. Bảo vệ dữ liệu khách hàng
Chúng tôi tôn trọng quyền riêng tư của bạn. Thông tin nhận dạng cá nhân được cung cấp cho chúng ta sẽ được thu thập, xử lý và lưu trữ trong cơ sở dữ liệu an toàn dưới sự kiểm soát của chúng tôi. chúng ta đã thực hiện các bước hợp lý để bảo vệ thông tin mà người dùng chia sẻ với chúng tôi, bao gồm nhưng không giới hạn việc thiết lập các quy trình, thiết bị và phần mềm để ngăn chặn truy cập trái phép hoặc tiết lộ thông tin này.
Chúng tôi chỉ cho phép nhân viên được ủy quyền của chúng tôi truy cập hoặc xử lý dữ liệu khách hàng và họ giữ bí mật tuyệt đối những thông tin đó. Chúng tôi hạn chế quyền truy cập thông tin của nhân viên, nhà thầu hoặc đại lý của Vdong. Những người cần biết thông tin này để xử lý thông tin cho chúng tôi phải tuân theo các biện pháp bảo vệ kỹ thuật và hợp đồng nghiêm ngặt và sẽ phải chịu trách nhiệm nếu họ không đáp ứng các nghĩa vụ này.
Hơn nữa, chúng tôi chỉ cấp cho bạn hoặc đại diện được ủy quyền của bạn quyền truy cập vào thông tin cá nhân của bạn. Chúng tôi sẽ không bán thông tin cá nhân của bạn cho bất kỳ ai vì bất kỳ mục đích nào.
Chỉnh sửa, cập nhật và xóa thông tin nhận dạng cá nhân. Bạn sẽ có phương tiện để truy cập hoặc chỉnh sửa dữ liệu cá nhân của mình trừ khi có lý do cụ thể, hợp đồng và pháp lý ngăn cản chúng tôi làm như vậy. Sẽ mất nhiều thời gian để chúng tôi cung cấp hoặc chỉnh sửa thông tin này cho bạn, nhưng hãy yên tâm là nó sẽ được thực hiện.
5. Xóa dữ liệu
Khi bạn muốn ngừng sử dụng và hủy tài khoản của mình, bạn có thể liên hệ với chúng tôi thông qua dịch vụ khách hàng trực tuyến và chúng tôi sẽ yêu cầu bạn cung cấp tài khoản và các chứng chỉ liên quan để xác minh và xác nhận danh tính thật của bạn. Sau khi xác minh, chúng tôi sẽ xóa tài khoản và dữ liệu liên quan của bạn. Nếu không tiện liên hệ với chúng tôi thông qua dịch vụ khách hàng trực tuyến, bạn có thể gửi yêu cầu qua email.
Truy cập về Chính sách quyền riêng tư của chúng tôi
Nếu bạn có bất kỳ câu hỏi nào về Chính sách bảo mật này, bạn có thể liên hệ với chúng tôi qua.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
}
img {
  display: block;
}
.imgBox1,
.imgBox2 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1,
.img2 {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.imgItem1,
.imgItem2 {
  width: 100%;
  margin: 0;
}
.img2{
  padding: 0 220px;
}

h1 {
  margin-top: 50px;
  margin-left: 490px;
  color: #3f4842;
  font-size: 40px;
  /* border-bottom: 5px solid #04358A; */
  display: inline-block;
}
.textBox {
  /* margin: 70px 0 150px; */
  margin: 30px 0 150px;
  width: 90%;
  font-size: 18px;
  color: #333333;
}
.textCard {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  /* border-radius: 32px; */
  /* box-shadow: 0 2px 20.8px -5px rgba(0, 0, 0, 0.25); */
  border: 2px solid #333;
  margin: 10px 0 200px;
}

.title-line {
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0baf60;
  margin: 0 500px;
}
.img1{
  margin-top: 20px;
}
</style>